import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Heart from '../assets/icons/Heart';
import Pronostix from '../assets/icons/Pronostix';
import { MarketingLayout } from '../layout/MarketingLayout';
import { useCurrentUser } from '../hooks/useCurrentUser';

function EuroLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g>
        <circle cx={10} cy={10} r={10} fillRule="evenodd" clipRule="evenodd" fill="#fff" />
        <linearGradient
          id="euro_svg_gradient"
          gradientUnits="userSpaceOnUse"
          x1={-188.436}
          y1={292.982}
          x2={-188.436}
          y2={291.982}
          gradientTransform="matrix(19.7737 0 0 -18.7658 3736.075 5499.287)"
        >
          <stop offset={0} stopColor="#cad1e1" />
          <stop offset={1} stopColor="#8a889c" />
        </linearGradient>
        <path
          d="M11.3 19.9c-.5.1-.9.1-1.3.1s-.9 0-1.3-.1l-1.5-4.8c-.3-1-1.3-1.7-2.4-1.7H.6c-.2-.6-.4-1.2-.4-1.8L3.9 9c.9-.6 1.3-1.7 1-2.8L3.6 2.3c.5-.4 1-.8 1.6-1.1l3.4 2.4c.9.6 2.1.6 3 0l3.3-2.5c.6.3 1.1.7 1.6 1.1L15.3 6c-.3 1 .1 2.2.9 2.8l3.7 2.6c-.1.6-.2 1.2-.4 1.8h-4.2c-1.1 0-2 .7-2.4 1.7l-1.6 5z"
          fill="url(#euro_svg_gradient)"
        />
        <path
          d="M11 13.3c.9 0 1.7-.6 2-1.4l.6-1.9c.3-.9 0-1.8-.8-2.3l-1.6-1.5c-.7-.5-2.2-.2-2.5 0-.1.2-.6.7-1.5 1.5-.7.5-1 1.5-.8 2.3l.6 1.9c.3.9 1.1 1.4 2 1.4h2z"
          fill="#fff"
        />
        <path
          d="M11.4 11.4l.6-1.9c.1-.2 0-.4-.2-.5l-1.6-1.2c-.1-.1-.3-.1-.5 0L8.2 9c-.2.1-.3.3-.2.5l.6 1.9c.1.2.2.3.4.3h2c.2 0 .3-.1.4-.3z"
          fill="#d1d1e2"
        />
        <path d="M0 9.6c.1-2.2.9-4.3 2.3-6l.9 3c.1.3 0 .7-.3.9L0 9.6z" fill="#c2e026" />
        <path d="M9.5 2.3c.3.2.7.2 1 0L13 .5c-2-.6-4.1-.6-6.1 0l2.6 1.8z" fill="#ed72c0" />
        <path d="M17.1 7.6l2.9 2c-.1-2.2-.9-4.3-2.3-6l-.9 3c-.1.4 0 .7.3 1z" fill="#4ec1fe" />
        <path d="M5.6 15.6c-.1-.4-.5-.6-.8-.6H1.3c1.2 2.1 3.2 3.7 5.5 4.5l-1.2-3.9z" fill="#fda43c" />
        <path d="M15.2 15c-.4 0-.7.2-.8.6l-1.3 3.9c2.3-.8 4.3-2.4 5.5-4.5h-3.4z" fill="#fb3000" />
      </g>
    </svg>
  );
}

const Hero = () => {
  const { user } = useCurrentUser();
  return (
    <div className="grid md:grid-cols-2 gap-8">
      <div className=" mt-10 md:mt-20 flex flex-col space-y-8">
        <div>
          <div className="rounded-full font-bold inline-flex bg-gray-200 px-3 py-1 justify-center content-center items-center">
            <EuroLogo className="h-5 w-5 mr-2" />
            <span className="mt-1 text-sm">EURO 2020 EDITION</span>
          </div>
        </div>
        <div className="font-bold text-5xl md:text-6xl">Fais tes pronostics et défie tes amis.</div>
        <div className="text-lg text-gray-500">
          Invite tes amis, crée des groupes et fais tes prévisions sur l’Euro 2020 pour des compétitions de folie.
        </div>
        <div className="block">
          <Link href={user ? '/app' : '/login'}>
            <a className="font-bold px-8 py-4 rounded-lg bg-blue-500 text-white hover:bg-blue-600 shadow-marketing-blue">
              Commencer gratuitement
            </a>
          </Link>
        </div>
        <div>Aucune publicité — Respect de la vie privée</div>
      </div>
      <div>
        <Image src="/assets/mkg_1.png" width={664} height={633} layout="responsive" />
      </div>
    </div>
  );
};

type HowWorkItemIcon = React.VFC<{ className: string }>;

const GroupSvg: HowWorkItemIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" {...props}>
      <g>
        <linearGradient
          id="GroupSvgGradient"
          gradientUnits="userSpaceOnUse"
          x1={-170.097}
          y1={330.651}
          x2={-170.097}
          y2={329.651}
          gradientTransform="matrix(72 0 0 -72 12283 23806.842)"
        >
          <stop offset={0} stopColor="#fdd740" />
          <stop offset={1} stopColor="#faae1e" />
        </linearGradient>
        <path
          d="M16 0h40c8.8 0 16 7.2 16 16v40c0 8.8-7.2 16-16 16H16C7.2 72 0 64.8 0 56V16C0 7.2 7.2 0 16 0z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#GroupSvgGradient)"
        />
        <path fill="none" d="M20 20h32v32H20z" />
        <path
          d="M24 43.3v4.1c0 .3.3.6.7.6h4.1c.2 0 .3-.1.5-.2l14.6-14.5-5-5-14.7 14.5c-.1.1-.2.3-.2.5zm23.6-13.9c.5-.5.5-1.4 0-1.9l-3.1-3.1c-.5-.5-1.4-.5-1.9 0l-2.4 2.4 5 5 2.4-2.4z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

const FriendsSvg: HowWorkItemIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" enableBackground="new 0 0 72 72" {...props}>
      <filter colorInterpolationFilters="auto" id="prefix__b">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <g>
        <linearGradient
          id="FriendsSvgGradient"
          gradientUnits="userSpaceOnUse"
          x1={-170.097}
          y1={330.651}
          x2={-170.097}
          y2={329.651}
          gradientTransform="matrix(72 0 0 -72 12283 23806.842)"
        >
          <stop offset={0} stopColor="#5fecac" />
          <stop offset={1} stopColor="#31d574" />
        </linearGradient>
        <path
          d="M16 0h40c8.8 0 16 7.2 16 16v40c0 8.8-7.2 16-16 16H16C7.2 72 0 64.8 0 56V16C0 7.2 7.2 0 16 0z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#FriendsSvgGradient)"
        />
        <g transform="translate(20 20)" filter="url(#prefix__b)">
          <path
            d="M9.3 13.3H6.7v-2.7c0-.7-.6-1.3-1.3-1.3S4 9.9 4 10.7v2.7H1.3c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3H4v2.7c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3V16h2.7c.7 0 1.3-.6 1.3-1.3.1-.8-.5-1.4-1.3-1.4zM24 14.7c2.2 0 4-1.8 4-4s-1.8-4-4-4c-.4 0-.8.1-1.2.2.8 1.1 1.2 2.4 1.2 3.8s-.5 2.7-1.2 3.8c.4.1.8.2 1.2.2zm-6.7 0c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 2.6c-2.7 0-8 1.3-8 4v1.3c0 .7.6 1.3 1.3 1.3H24c.7 0 1.3-.6 1.3-1.3v-1.3c0-2.6-5.3-4-8-4zm8.9.2c1.1 1 1.8 2.2 1.8 3.8v2c0 .2 0 .5-.1.7h3.4c.4 0 .7-.3.7-.7v-2c0-2-3.2-3.3-5.8-3.8z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#1d1d1d"
          />
        </g>
      </g>
    </svg>
  );
};

const ForecastSvg: HowWorkItemIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" {...props}>
      <g>
        <linearGradient
          id="ForecastSvgGradient"
          gradientUnits="userSpaceOnUse"
          x1={-170.097}
          y1={330.651}
          x2={-170.097}
          y2={329.651}
          gradientTransform="matrix(72 0 0 -72 12283 23806.842)"
        >
          <stop offset={0} stopColor="#b63ef8" />
          <stop offset={1} stopColor="#7f1def" />
        </linearGradient>
        <path
          d="M16 0h40c8.8 0 16 7.2 16 16v40c0 8.8-7.2 16-16 16H16C7.2 72 0 64.8 0 56V16C0 7.2 7.2 0 16 0z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#ForecastSvgGradient)"
        />
        <path fill="none" d="M20 20h32v32H20z" />
        <path
          d="M44.6 26.4h-5.1C38.9 25 37.6 24 36 24s-2.9 1-3.4 2.4h-5.2c-1.3 0-2.4 1.1-2.4 2.4v16.8c0 1.3 1.1 2.4 2.4 2.4h17.1c1.3 0 2.4-1.1 2.4-2.4V28.8c.1-1.3-1-2.4-2.3-2.4zm-8.6 0c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2.5-1.2 1.2-1.2zm1.2 16.8h-6.1c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h6.1c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2zm3.7-4.8h-9.8c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h9.8c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2zm0-4.8h-9.8c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h9.8c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

const HowWorkItem = ({
  title,
  description,
  logo: Logo,
}: {
  title: string;
  description: string;
  logo: HowWorkItemIcon;
}) => {
  return (
    <div className="flex flex-col items-center text-center space-y-4">
      <Logo className="h-20 w-20" />
      <span className="font-bold text-2xl">{title}</span>
      <span className="">{description}</span>
    </div>
  );
};

const HowItWorksDivider = () => {
  return (
    <div className="flex items-center space-x-2">
      <div className="h-4 w-4 rounded-full  border border-2 border-gray-100" />
      <div className="flex-1 border border-dashed border-2 border-gray-100 h-0" />
      <div className="h-4 w-4 rounded-full  border border-2 border-gray-100" />
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div className="grid md:grid-cols-3 gap-16 md:gap-8">
      <div className="md:col-start-1 md:row-start-1">
        <HowWorkItem
          description="Crée et rejoins autant de groupes que tu le souhaites, sans limite de membres (et sans masque 😷)."
          logo={GroupSvg}
          title="Crée un groupe"
        />
      </div>
      <div className="md:col-start-2 md:row-start-1">
        <HowWorkItem
          description="Consulte les pronostics de tes amis et montre leur qui est le meilleur grâce au classement en temps réel."
          logo={FriendsSvg}
          title="Invite tes amis"
        />
      </div>
      <div className="md:col-start-3 md:row-start-1">
        <HowWorkItem
          description="Pronostique sur tous les matchs de l’Euro et récolte un max de points en tapant dans le mille."
          logo={ForecastSvg}
          title="Fais tes pronostics"
        />
      </div>
      <div className="hidden md:block md:col-start-1 col-span-2	row-start-1	flex items-center justify-center align-middle mt-8 ">
        <div className="w-1/2 px-12 mx-auto">
          <HowItWorksDivider />
        </div>
      </div>
      <div className="hidden md:block col-start-2	col-span-2	row-start-1	flex items-center justify-center align-middle mt-8">
        <div className="w-1/2 px-12 mx-auto">
          <HowItWorksDivider />
        </div>
      </div>
    </div>
  );
};

const ImageBody = () => {
  return (
    <div className="grid gap-8 md:grid-cols-2 items-center mb-20">
      <div>
        <Image src="/assets/mkg_2.png" height={400} width={600} layout="responsive" alt="" />
      </div>
      <div className=" flex flex-col space-y-4">
        <span className="text-3xl font-bold block">
          Des compétitions funs et privées entre amis <span className="font-medium">(ou collègues 🤓)</span>.
        </span>
        <span className="text-lg text-gray-500">
          Avec les groupes, invite toutes les personnes que tu souhaites et garde un oeil sur leurs pronostics. Le
          classement en temps réel sera là pour vous départager.
        </span>
      </div>
      <div className="md:col-start-2">
        <Image src="/assets/mkg_3.png" height={400} width={600} layout="responsive" alt="" />
      </div>
      <div className="md:col-start-1 md:row-start-2 justify-self-end flex flex-col space-y-4">
        <span className="text-3xl font-bold block">Une interface simple et entièrement responsive📱.</span>
        <span className="text-lg text-gray-500">
          L’interface s’adapte à toutes les tailles d’écran. Que ce soit sur mobile, tablette ou ordinateur de bureau,
          il n’y a plus d’excuse pour ne pas être sur Pronostix.
        </span>
      </div>
    </div>
  );
};

const SupporterBlock = () => {
  const { user } = useCurrentUser();
  const baseStyleButtons = `rounded-lg py-4 px-6 text-center flex justify-center md:justify-start w-full md:w-auto`;
  return (
    <div className="bg-gradient-to-r from-[#00C6FF] via-[#0E5EFF] to-[#9C2BE8] md:rounded-lg py-12 px-8 flex flex-col space-y-4 items-center mb-10 relative overflow-hidden">
      <div className="text-2xl text-white font-bold">🇫🇷 Allez les bleus 🇫🇷</div>
      <div className="text-xl text-white text-center">
        Pronostix est conçu et développé en France par 3 jeunes nordistes.{' '}
      </div>
      <div className="flex flex-col space-y-4 md:space-y-0 font-bold md:space-x-4 md:flex-row z-10">
        <Link href={user ? '/app' : '/login'}>
          <a className={`${baseStyleButtons} bg-white text-blue-500 hover:opacity-90`}>Commencer gratuitement</a>
        </Link>
        <Link href="/support-us">
          <a className={`${baseStyleButtons} ring-1 ring-white text-white hover:bg-white-op-10`}>
            <Heart className="h-4 w-4 mr-2 mt-0.5" aria-hidden={true} />
            Nous soutenir
          </a>
        </Link>
      </div>
      <Pronostix className="h-120 w-120 absolute translate-y-20 md:-left-52 md:top-0 transform rotate-12 opacity-[0.1] text-white" />
      <Pronostix className="h-120 w-120 absolute -right-52 -top-12 transform -rotate-12 opacity-[0.1] text-white hidden md:block" />
    </div>
  );
};

const ContentSpacerBlock: React.FC = ({ children }) => {
  return <div className="mx-4 lg:mx-8">{children}</div>;
};

export const App = () => {
  return (
    <MarketingLayout>
      <div className="space-y-10 md:space-y-24">
        <ContentSpacerBlock>
          <Hero />
        </ContentSpacerBlock>
        <ContentSpacerBlock>
          <HowItWorks />
        </ContentSpacerBlock>
        <ContentSpacerBlock>
          <ImageBody />
        </ContentSpacerBlock>
        <div className="md:mx-4 lg:mx-8">
          <SupporterBlock />
        </div>
      </div>
    </MarketingLayout>
  );
};
export default App;
